import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Popover,
  Modal,
  Button,
  OverlayTrigger,
} from "react-bootstrap";
import serieImg from "../../assets/css/img/serie.jpg";
import "bootstrap/dist/css/bootstrap.css";

export default function Popup({
  checkAccount,
  cleanPopup,
  rut,
  saveCedula,
  mensaje,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    cleanPopup();
    setShow(false);
  };

  return (
    <>
      <a
        className="text-primary"
        onClick={() => setShow(true)}
        style={{
          cursor: "pointer",
        }}
      >
        Haz click aquí
      </a>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <strong>Ingresa a tus ahorros</strong>
        </Modal.Header>
        <Modal.Body>
          <UserInfoForm rut={rut} saveCedula={saveCedula} mensaje={mensaje} />
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-center">
              <button
                className="btn btn-sm btn-warning"
                type="button"
                onClick={() => checkAccount(".")}
              >
                <span className="ui-button-text ui-c">Ver mis ahorros</span>
              </button>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const DocumentPopover = () => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Número de Documento</Popover.Title>
      <Popover.Content>
        <img className="img-fluid" src={serieImg} alt="Número de Documento" />
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <Button variant="">¿Necesitas ayuda?</Button>
    </OverlayTrigger>
  );
};

const UserInfoForm = ({ rut, saveCedula, mensaje }) => (
  <Container>
    <Row className="justify-content-center">
      <Col sm={12} md={8}>
        <UserInput label="RUT:" value={rut} />
        <UserInput
          label="Número de Documento:"
          inputType="password"
          name="cedula"
          maxLength="9"
          onChange={saveCedula}
        />
        <HelpButton />
      </Col>
    </Row>
    <ErrorMessage mensaje={mensaje} />
  </Container>
);

const UserInput = ({
  label,
  value,
  inputType = "text",
  name,
  maxLength,
  onChange,
}) => (
  <>
    <Row className=" justify-content-center mt-3 bold font-weight-bold">
      {label}
    </Row>
    <Row className="mt-2 justify-content-center">
      {value ? (
        <span>{value}</span>
      ) : (
        <input
          className="info_input mt-2 ml-2 form-control"
          type={inputType}
          name={name}
          maxLength={maxLength}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </Row>
  </>
);

const HelpButton = () => (
  <Row className="mt-5 bold font-weight-bold justify-content-center">
    <DocumentPopover />
  </Row>
);

const ErrorMessage = ({ mensaje }) => (
  <Row
    className="justify-content-center small-text"
    style={{ color: "#D80132", fontSize: "0.8em" }}
  >
    {mensaje}
  </Row>
);
