import React from "react";

const footer = (props) => {
  return (
    <div id="footer">
      <div
        className="text-center pb-3"
        style={{
          fontFamily: "Open Sans",
          fontWeight: "bold",
          fontSize: "calc(.7em + .5vw)",
          color: "#666666",
        }}
      >
        Si necesitas ayuda, contactanos al 600 444 8080
      </div>
      <div className="subcontent">
        *El monto a rescatar será calculado sobre el ahorro recaudado hasta la
        fecha.
        <br />
        Seguro Intermediado por BancoEstado Corredores de Seguros S.A. La
        Compañía que asume los riesgos es MetLife Chile Seguros de Vida S.A.
        BancoEstado Corredores de Seguros S.A. es filial de BancoEstado.
        <br />V{process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
};

export default footer;
