import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import "bootstrap/dist/css/bootstrap.css";

export default function PopupRut(props) {
  const [show, setShow] = useState(true);

  const handleClose = () => props.setShow(false);
  
  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Lo Sentimos!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='text-justify px-4'>
            {/* <Row className="justify-content-md-center">{props.mensaje}</Row> */}

            <Row className="justify-content-md-center">
              Intentalo nuevamente
            </Row>

            {/* <Row></Row> */}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-md-center">
              <button className="btn btn-sm btn-warning" type="button" onClick={ () => window.location.href = "/"}>
                <span className="ui-button-text ui-c ">Reintentar</span>
              </button>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
