import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card } from "react-bootstrap";
import NumberFormat from "react-number-format";

export default function PlanesTable(props) {
  let plan = props.plan;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575.98);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575.98);
    };

    // Añadir listener
    window.addEventListener("resize", handleResize);

    // Llamar a handleResize inmediatamente para establecer el estado inicial
    handleResize();

    // Remover listener al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="text-center">
      {isMobile ? (
        <div>
          <Card className="mb-2 p-1">
            <Card.Header className="bg-warning text-white p-0">
              Seguro
            </Card.Header>
            <Card.Body className="p-2">{plan.nombre}</Card.Body>
          </Card>
          <Card className="mb-2 p-1">
            <Card.Header className="bg-warning text-white p-0">
              Inicio vigencia seguro
            </Card.Header>
            <Card.Body className="p-2">{plan.fecha_inicio}</Card.Body>
          </Card>
          <Card className="mb-2 p-1">
            <Card.Header className="bg-warning text-white p-0">
              Prima mensual
            </Card.Header>
            <Card.Body className="p-2">
              {" "}
              <Row>
                <Col className="text-center" style={{ fontSize: "15px" }}>
                  <NumberFormat
                    value={plan.prima_mensual_uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  className="text-center font-weight-bold"
                  style={{ fontSize: "15px" }}
                >
                  <NumberFormat
                    value={plan.prima_mensual_clp}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"($"}
                    suffix={"*)"}
                    decimalScale={0}
                  />{" "}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-2 p-1">
            <Card.Header className="bg-warning text-white p-0">
              Cantidad primas pagadas
            </Card.Header>
            <Card.Body className="p-2"> {plan.primas_pagadas}</Card.Body>
          </Card>
          <Card className="mb-2 p-1">
            <Card.Header className="bg-warning text-white p-0">
              Monto primas pagadas
            </Card.Header>
            <Card.Body className="p-2">
              {" "}
              <Row>
                <Col className="text-center" style={{ fontSize: "15px" }}>
                  <NumberFormat
                    value={plan.monto_pagado_uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    decimalScale={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  className="text-center font-weight-bold"
                  style={{ fontSize: "15px" }}
                >
                  <NumberFormat
                    value={plan.monto_pagado_clp}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"($"}
                    suffix={"*)"}
                    decimalScale={0}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-2 p-1">
            <Card.Header className="bg-warning text-white p-0">
              Disponible para retiro
            </Card.Header>
            <Card.Body className="p-2">
              {" "}
              <Row>
                <Col className="text-center" style={{ fontSize: "15px" }}>
                  <NumberFormat
                    value={plan.monto_disponible_uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  className="text-center font-weight-bold"
                  style={{ fontSize: "15px" }}
                >
                  <NumberFormat
                    value={plan.monto_disponible_clp}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"($"}
                    suffix={"*)"}
                    decimalScale={0}
                  />{" "}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <Table
          bordered
          hover
          responsive="sm"
          className="mb-2 text-center table-sm"
        >
          <thead className="thead-warning">
            <tr>
              <th className="align-top">Seguro</th>
              <th className="align-top">Inicio vigencia seguro</th>
              <th className="align-top">Prima mensual</th>
              <th className="align-top">Cantidad primas pagadas</th>
              <th className="align-top">Monto primas pagadas</th>
              <th className="align-top">Disponible para retiro</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ fontSize: "15px" }} title="Seguro">
                {plan.nombre}
              </td>
              <td style={{ fontSize: "15px" }} title="Inicio vigencia seguro">
                {plan.fecha_inicio}
              </td>
              <td title="Prima mensual">
                <Row>
                  <Col className="text-center" style={{ fontSize: "15px" }}>
                    <NumberFormat
                      value={plan.prima_mensual_uf}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"UF "}
                      suffix={""}
                      decimalScale={2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="text-center font-weight-bold"
                    style={{ fontSize: "15px" }}
                  >
                    <NumberFormat
                      value={plan.prima_mensual_clp}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"($"}
                      suffix={"*)"}
                      decimalScale={0}
                    />{" "}
                  </Col>
                </Row>
              </td>

              <td style={{ fontSize: "15px" }} title="Cantidad primas pagadas">
                {plan.primas_pagadas}
              </td>
              <td title="Monto primas pagadas">
                <Row>
                  <Col className="text-center" style={{ fontSize: "15px" }}>
                    <NumberFormat
                      value={plan.monto_pagado_uf}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"UF "}
                      decimalScale={2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="text-center font-weight-bold"
                    style={{ fontSize: "15px" }}
                  >
                    <NumberFormat
                      value={plan.monto_pagado_clp}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"($"}
                      suffix={"*)"}
                      decimalScale={0}
                    />
                  </Col>
                </Row>
              </td>

              <td title="Disponible para retiro">
                <Row>
                  <Col className="text-center" style={{ fontSize: "15px" }}>
                    <NumberFormat
                      value={plan.monto_disponible_uf}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"UF "}
                      suffix={""}
                      decimalScale={2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="text-center font-weight-bold"
                    style={{ fontSize: "15px" }}
                  >
                    <NumberFormat
                      value={plan.monto_disponible_clp}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"($"}
                      suffix={"*)"}
                      decimalScale={0}
                    />{" "}
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
}
