import React from "react";
import "../../assets/css/Landing/spinner.css";

class LoadingOverlay extends React.Component {
  render() {
    return (
      <div className="loading-overlay">
        <div className="loader"></div>
      </div>
    );
  }
}

export default LoadingOverlay;
