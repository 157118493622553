import React, { Component, } from "react";
//import React, {Component} from "react";
import Header from "./Header";
import Banner from "./Banner";
import BodyRut from "./BodyRut";
// import BodyLogin from "./BodyLogin";
import Footer from "./Footer";
import MaintenanceModal from "./MaintenanceModal";

/* const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
//  window.location.reload(true);
}; */

class Home extends Component {
/*   componentDidMount(){
    // refreshCacheAndReload();  
  } */
  constructor(props) {
    super(props);
    const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODAL === "true";
    this.state = {
      isMaintenance,
      showModal: isMaintenance
    };
  }

  handleCloseModal = () => {
    if (!this.state.isMaintenance) {
      this.setState({ showModal: false });
    }
  }

  render() {
    const { isMaintenance, showModal } = this.state;
    const message = process.env.REACT_APP_MESSAGE_MODAL || "Le informamos que en este momento nuestra plataforma para realizar rescates de seguros está temporalmente fuera de servicio.";

    return (
      <div className="content">
        <div className="card m-2">
          <Header />
          <Banner />
          <div className="card-body">
            <BodyRut />
            <hr />
            <Footer />
          </div>
        </div>

        <MaintenanceModal
          show={showModal}
          handleClose={this.handleCloseModal}
          isMaintenance={isMaintenance}
          message={message}
        />
      </div>
    );

  }
}

export default Home;
