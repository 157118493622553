import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Container, Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

class BodyRescate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personal: null,
      errors: {},
      fields: {},
      uf: 0,
      porcentaje: 0,
      rescate: {
        uf: 0,
        clp: 0,
      },
      saldo: {
        uf: 0,
        clp: 0,
      },
    };

    this.state.personal = this.props.state.personal;
    this.state.plan = this.props.state.plan;
    this.state.uf = this.props.state.uf;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  //   calling web services
  handleValidation() {
    if (this.state.porcentaje === 0) {
      let errors = {};
      errors["porcentaje"] = "Debes elegir un % entre 1 y 100 para continuar."; //"El porcentaje no puede ser cero";
      this.setState({ errors: errors });
      return false;
    }

    return true;
  }
  requestRescate(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      let retirar = {
        porcentaje: this.state.porcentaje,
        rescate: this.state.rescate,
        saldo: this.state.saldo,
      };
      this.props.history.push({
        pathname: "/bancarios",
        state: {
          personal: this.state.personal,
          plan: this.state.plan,
          uf: this.state.uf,
          retirar: retirar,
        },
      });
    }
  }

  myFormat(num) {
    return num + " %";
  }
  handlePercent(event) {
    let value;
    if (typeof event === "object" && event.target) {
      value = event.target.value;
    } else {
      value = event;
    }

    if (!isNaN(value) && value >= 0 && value <= 100) {
      let rescate = { ...this.state.rescate };
      let saldo = { ...this.state.saldo };

      rescate.uf = (this.state.plan.monto_disponible_uf * value) / 100;
      rescate.clp = (this.state.plan.monto_disponible_clp * value) / 100;
      saldo.uf =
        parseFloat(this.state.plan.monto_disponible_uf) -
        parseFloat(rescate.uf);
      saldo.clp =
        parseFloat(this.state.plan.monto_disponible_clp) -
        parseFloat(rescate.clp);

      this.setState({
        porcentaje: value,
        rescate: rescate,
        saldo: saldo,
        errors: { ...this.state.errors, porcentaje: null },
      });
    } else if (value === "" || value === "-") {
      this.setState({ porcentaje: value });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          porcentaje: "Por favor, ingrese un número entre 0 y 100.",
        },
      });
    }
  }

  render() {
    return (
      <Container className="mt-5 mx-0 col-12 p-0">
        <Row className="subtitle mb-3 text-center text-lg-left">
          <Col>Solicitar rescate Vida con Ahorro</Col>
        </Row>
        <Col className="">
          <Row className="font-weight-bold mb-2">
            <Col>
              <h5 className="text-center">Datos del rescate</h5>
            </Col>
          </Row>
          <Table bordered-sm hover-sm responsive className="mb-2 text-center">
            <thead>
              <tr className="text-warning">
                <th>Disponible para retiro</th>
                <th>Porcentaje a retirar</th>
                <th>Monto a retirar</th>
                <th>Saldo después del retiro</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-title="Disponible para retiro">
                  {" "}
                  <Row>
                    <Col className="text-center">
                      <NumberFormat
                        value={this.state.plan.monto_disponible_uf}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        prefix={"UF "}
                      />{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center font-weight-bold">
                      <NumberFormat
                        value={this.state.plan.monto_disponible_clp}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"$ "}
                        suffix={"*"}
                      />{" "}
                    </Col>
                  </Row>
                </td>
                <td
                  data-title="Porcentaje a retirar"
                  className="p-0 pt-3 col-lg-3"
                >
                  <div className="input-group d-flex justify-content-center">
                    <input
                      type="text"
                      className="form-control p-1 col-5 text-center"
                      value={this.state.porcentaje || ""}
                      onChange={this.handlePercent.bind(this)}
                      pattern="\d*"
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text pb-0 pt-0 px-1">
                        <strong>%</strong>
                      </span>
                      <button
                        className="btn btn-outline-warning btn-sm pb-0 pt-0"
                        type="button"
                        onClick={() =>
                          this.handlePercent(
                            parseInt(this.state.porcentaje || 0) + 1
                          )
                        }
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                      <button
                        className="btn btn-outline-warning btn-sm pb-0 pt-0"
                        type="button"
                        onClick={() =>
                          this.handlePercent(
                            parseInt(this.state.porcentaje || 0) - 1
                          )
                        }
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </td>

                <td data-title="Monto a retirar">
                  {" "}
                  <Row>
                    <Col className="text-center">
                      <NumberFormat
                        value={this.state.rescate.uf}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        prefix={"UF "}
                      />{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center font-weight-bold">
                      <NumberFormat
                        value={this.state.rescate.clp}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"$ "}
                        suffix={"*"}
                      />{" "}
                    </Col>
                  </Row>
                </td>
                <td data-title="Saldo después del retiro">
                  {" "}
                  <Row>
                    <Col className="text-center">
                      <NumberFormat
                        value={this.state.saldo.uf}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        prefix={"UF "}
                      />{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center font-weight-bold">
                      <NumberFormat
                        value={this.state.saldo.clp}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        prefix={"$ "}
                        suffix={"*"}
                      />{" "}
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>

          <Row className="mb-2 font-weight-bold info_right mt-4">
            <Col>
              <NumberFormat
                value={this.state.uf}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={
                  "*Valor referencial calculado según el valor de la UF del día de hoy $"
                }
                decimalScale={2}
                className=""
              />
            </Col>
          </Row>
          <Row className="mb-2 mt-4 text-justify info_right">
            <Col>
              El monto a retirar será calculado sobre el ahorro recaudado hasta
              la fecha. Considerar que puede existir un desfase por las primas
              que se encuentren en curso y que el monto final puede estar sujeto
              al impuesto a la renta, de acuerdo a lo definido por el SII,
              incidiendo en el monto final a percibir.
            </Col>
          </Row>

          <div>
            {this.state.errors["porcentaje"] && (
              <Row className="justify-content-center">
                <Col className="text-center">
                  <strong
                    className="text-danger p-0 p-lg-2"
                    style={{ fontWeight: "bold", fontSize: "13px" }}
                  >
                    {this.state.errors["porcentaje"]}
                  </strong>
                </Col>
              </Row>
            )}
            <Row className="mt-4 justify-content-center">
              <button
                className="btn btn-warning btn-sm d-inline-block col-lg-2"
                type="button"
                onClick={this.requestRescate.bind(this)}
              >
                <span className="ui-button-text ui-c ">Continuar</span>
              </button>
            </Row>
          </div>

          <Row className="d-flex justify-content-center">
            {/* <Link to="/"> */}
            <p
              className="resend_code_label text-warning"
              style={{
                cursor: "pointer",
              }}
              onClick={this.props.logout}
            >
              Cerrar Sesión
            </p>
            {/* </Link> */}
          </Row>
        </Col>
      </Container>
    );
  }
}

export default withRouter(BodyRescate);
