import React from "react";
import { Modal, Button  } from "react-bootstrap";

const MaintenanceModal = ({ show, handleClose, isMaintenance, message }) => {
  return (
    <Modal 
      size="xl" 
      show={show} 
      onHide={isMaintenance ? () => {} : handleClose} 
      backdrop={isMaintenance ? "static" : true} // evita cerrar haciendo click afuera
      keyboard={!isMaintenance} // evita cerrar con la tecla ESC
    >
      <Modal.Header closeButton={!isMaintenance}>
        <Modal.Title>Aviso Importante</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4" style={{ textAlign: "justify" }}>
        <p>{message}</p>
        <p className="font-weight-lighter text-muted mt-5">
          Agradecemos su comprensión y lamentamos los inconvenientes ocasionados.
        </p>
      </Modal.Body>
      {!isMaintenance && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MaintenanceModal;