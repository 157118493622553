import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import PlanesTable from "./PlanesTable";
import PopRescates from "./PopRescates";
import PopSimulador from "./PopSimulador";
import MetlifeService from "../../services/MetlifeDataService";
import ReactTooltip from "react-tooltip";
import {
  Row,
  Col,
  Form,
  Container,
  Card,
  Modal,
  Button,
} from "react-bootstrap";
import NumberFormat from "react-number-format";

import btn_down from "../../assets/css/img/arrow-down.png";
import loading from "../../assets/css/img/loading-orange.gif";

export default class Planes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opcion: null,
      uf: null,
      rut: null,
      token: null,
      hasError: false,
      hasLimitError: false,
      modalShow: false,
      modalShowWork: false,
      modalTitle: "",
      modalMsg: "",
      nombre: "",
    };

    if (this.props.planes != null) {
      this.state.planes = this.props.planes;
      this.state.uf = this.props.uf;
      this.state.rut = this.props.rut;
      this.state.token = this.props.token;
      this.state.nombre = this.props.nombre;
    }
  }

  closeModal = () => this.setState({ modalShow: false });

  handleChange(id) {
    this.props.handlePlan(id);
  }

  chkOpt(p) {
    return p.ticketNumber === null || p.monto_disponible_uf === 0
      ? "disabled"
      : "";
  }

  txtSM(p) {
    return p.monto_disponible_uf === 0 ? "* Seguro sin monto para retiro " : "";
  }

  downloadPdf = (plan) => {
    this.setState({
      modalShow: true,
      modalShowWork: true,
      modalTitle: "Descargando...",
    });

    if (plan.ticketNumber === null) {
      console.log("Error");
    } else {
      let data = {
        policyNumber: plan.policy,
        ticketNumber: plan.ticketNumber,
        rut: this.state.rut,
      };

      MetlifeService.getCertificate(this.state.token, data)
        .then((res) => {
          this.setState({
            modalShow: true,
            modalShowWork: false,
            modalTitle: "Descarga Certificado",
            modalMsg:
              "Hola, tu certificado se ha generado exitósamente. Para abrir el documento, debes ingresar tu RUT sin puntos ni dígito verificador.",
          });
          // console.log('cerificate response', res);
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([res.data], { type: "application/pdf" });
          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement("a");
            link.href = objUrl;
            link.download = "certificado_cobertura";
            link.click();
            // For Firefox it is necessary to delay revoking the ObjectURL.
            //const fncST =setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
          }
        })
        .catch((err) => {
          this.setState({
            modalShow: true,
            modalShowWork: false,
            modalTitle: "¡Lo sentimos!",
          });
          console.log("Err get certificate", err);

          let status = 500;
          try {
            status = err.response.status;
          } catch (err) {
            console.log(err);
          }

          if (status === 429) {
            this.setState({
              hasLimitError: true,
              modalMsg:
                "Tu certificado ya fue solicitado previamente. Por favor, inténtalo más tarde.",
            });
          } else {
            this.setState({
              hasError: true,
              modalMsg: "Algo salió mal, por favor, inténtalo más tarde.",
            });
          }
        });
    }
  };

  render() {
    return (
      <Accordion defaultActiveKey="0" className="planes_accordion">
        {React.Children.toArray(
          this.state.planes.map((vPlan, index) => (
            <Card>
              <Card.Header>
                {this.chkOpt(vPlan) === "disabled" && (
                  <ReactTooltip
                    id={"registerTip" + index}
                    place="top"
                    effect="solid"
                    className="tooptipcls"
                  >
                    {this.txtSM(vPlan)}
                  </ReactTooltip>
                )}
                <Row>
                  <Form.Check
                    inline
                    type="radio"
                    name="info_opt"
                    onChange={this.handleChange.bind(this, vPlan)}
                    disabled={this.chkOpt(vPlan)}
                    id={"inline-radio-" + String(index)}
                    style={{
                      transform: "scale(1.5)",
                      marginLeft: "1.5rem",
                    }}
                  />

                  <Col className="planes_head">
                    <span>{vPlan.nombre}</span>
                  </Col>
                  <Col className="col-lg-1 col-3">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={String(index)}
                    >
                      <img alt="arrow down" src={btn_down} />
                    </Accordion.Toggle>
                  </Col>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey={String(index)} className="p-0">
                <Card.Body className="p-1">
                  <span style={{ color: "#a94442" }}>{vPlan.errorMessage}</span>
                  <PlanesTable plan={vPlan} />
                  <Row className="ml-2 minilabel justify-content-center">
                    <NumberFormat
                      value={this.state.uf}
                      displayType={"text"}
                      decimalScale={2}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={
                        "*Valor referencial calculado según la UF del día de hoy $"
                      }
                    />
                  </Row>
                  <Container>
                    <Row className="justify-content-start">
                      <Col className="col-md-4 col-12 d-flex justify-content-center">
                        <PopRescates
                          plan={vPlan}
                          rut={this.state.rut}
                          token={this.state.token}
                          nombre={this.state.nombre}
                        />
                      </Col>
                      <Col className="col-md-3 d-flex col-12 justify-content-center">
                        <PopSimulador
                          plan={vPlan}
                          uf={this.state.uf}
                          token={this.state.token}
                        />
                      </Col>
                      <Col className="col-lg-5 d-flex justify-content-center">
                        <button
                          className="btn btn-outline-warning btn-sm mt-0 mt-lg-5 mb-3"
                          type="button"
                          onClick={this.downloadPdf.bind(this, vPlan)}
                        >
                          Descargar tu certificado de cobertura
                        </button>
                        <Modal
                          show={this.state.modalShow}
                          onHide={this.closeModal}
                          modalOptions={{ dismissible: false }}
                        >
                          <Modal.Header>
                            <Modal.Title>{this.state.modalTitle}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {this.state.modalShowWork ? (
                              <div className="btn-continuar ">
                                <img
                                  className="center"
                                  src={loading}
                                  alt="loading"
                                />
                              </div>
                            ) : (
                              <div>{this.state.modalMsg}</div>
                            )}
                          </Modal.Body>
                          <Modal.Footer>
                            {this.state.modalShowWork ? (
                              <p></p>
                            ) : (
                              <Row className="justify-content-md-center">
                                <button
                                  className="btn btn-sm btn-warning"
                                  type="button"
                                  onClick={this.closeModal}
                                >
                                  <span className="ui-button-text ui-c ">
                                    Aceptar
                                  </span>
                                </button>
                              </Row>
                            )}
                          </Modal.Footer>
                        </Modal>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))
        )}
      </Accordion>
    );
  }
}
