import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.css";

export default function PopupRut() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <strong>La plataforma no está disponible para el RUT ingresado.</strong>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <p className="mb-2">
                - Si gestionaste un retiro recientemente, reintenta mañana
                cuando tus montos estén actualizados.
              </p>
              <p className="mb-2">
                - Si contrataste recientemente, es posible que aún se esté
                gestionando tu acceso.
              </p>
              <p className="mb-2">
                - Si los casos anteriores no aplican, revisa haber escrito el
                RUT correcto.
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Container>
          <Row className="justify-content-center mb-3">
            <Col>
              <p className="text-muted font-weight-bold">
                Si necesitas ayuda, contáctanos al 6004448080
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <button
              className="btn btn-warning btn-sm w-25"
              type="button"
              onClick={() => window.location.reload()}
            >
              Salir
            </button>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
