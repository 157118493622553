import React from "react";
import img_banner from "../../assets/css/img/banner3.png";

const Banner = (props) => {
  return (
    <div className="banner position-relative">
      <img className="img-fluid" src={img_banner} alt="banner" />
      <div
        className="banner-text position-absolute text-white text-center"
        style={{
          top: "50%",
          right: "14%",
          transform: "translateY(-50%)",
          fontSize: "27px"
        }}
      >
        <p style={{ marginBottom: 0 }}>Piensa en los que más quieres,</p>
        <p style={{ fontWeight: "bold",  fontSize: "32px" }}>Con tus seguros con ahorro</p>
      </div>
    </div>
  );
};

export default Banner;
