export const replaceAll = (texto, listViejo, nuevo) => {
  listViejo.map((viejo) => {
    texto = texto.split(viejo).join(nuevo);
    return texto;
  });
  return texto;
};
// Ocultar el email
export const hideEmail = (email) => {
  if (email === null || email === "" || email === undefined) return "";
  return email.replace(/(.{3})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += "*";
    }
    return gp2;
  });
};
// Ocultar el telefono
export const hideSms = (phone) => {
  if (phone === null || phone === "" || phone === undefined) {
    return "";
  }
  var res = phone.match(/\d{4}$/g);
  return "*".repeat(phone.length - 4) + res;
};

// Funciones para validar el rut
export const validaRutDv = (T) => {
  let M = 0, S = 1;
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;

  const retval = S ? S - 1 : "k";
  return retval;
};

export const validaRut = (rutCompleto) => {
 
  rutCompleto = rutCompleto.replace(/[‐\-]/g, "-");

  if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;

  let [rut, dv] = rutCompleto.split("-");
  
  dv = dv.toUpperCase() === 'K' ? 'k' : dv;

  let vrd = validaRutDv(rut).toString();

  return vrd === dv;
};


export const rutWithFormat = (rut) => {
  var rutArray = rut.split("-");
  var bodyRut = rutArray[0].replace(/\./g, "");
  return bodyRut.padStart(14, 0);
};

export const getErrorMessage = (code) => {
  const errors = {
    "000":
      " Momentáneamente nuestra página tuvo un error. Gracias por tu comprensión.",
    111: " Momentáneamente nuestra página tuvo un error, presione el boton nuevamente. Gracias por tu comprensión.",
    "001": "Verifique su RUT",
    "002": "Verifique el monto",
    "003": "El RUT utilizado ha llegado al número máximo de compras de seguro",
    "004": "Código de verificación es inválido",
  };
  return errors[code || "000"];
};
