import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { rutWithFormat } from "../tools/Tools";
import MetlifeService from "../../services/MetlifeDataService";

import RescatesTable from "./RescatesTable";

//import loading from "../../assets/css/img/loading-orange.gif";

export default function PopRescates(props) {
  const [show, setShow] = useState(false);
  const [rescates, setRescates] = useState([]);
  const [plan, setPlan] = useState([]);
  //  const [cargando, setCargando] = useState(false);

  const handleShow = () => {
    let idClient = rutWithFormat(props.rut);
    let plan = props.plan;
    setPlan(plan);

    if (plan.ticketNumber === null) {
      setShow(true);
      setRescates([]);
    } else {
      MetlifeService.getHistoryPlans(props.token, { idCliente: idClient })
        .then((res) => {
          setShow(true);
          setRescates(res.data.listPHistoryRecueDTO);
          // rescates = ;
        })
        .catch((err) => {
          setShow(true);
          console.log("Err HistoryPolicyRescues", err);
        });
    }
  };

  const handleClose = () => setShow(false);

  const downloadPdf = () => {
    //    this.setState({cargando:true, });

    if (plan.ticketNumber === null) {
      console.log("Error");
    } else {
      let data = {
        rut: rutWithFormat(props.rut),
        nombre: props.nombre,
        rescates: rescates,
        nropoliza: plan.policy,
        nombrepoliza: plan.nombre,
      };

      MetlifeService.getCertPrev(props.token, data)
        .then((res) => {
          //this.setState({modalShow:true, modalShowWork:false, modalTitle: "Descarga Certificado", modalMsg:"Hola, tu certificado se ha generado exitósamente. Para abrir el documento, debes ingresar tu RUT sin puntos ni dígito verificador.",});
          // console.log('cerificate response', res);
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([res.data], { type: "application/pdf" });
          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement("a");
            link.href = objUrl;
            link.download = "solicitudes_anteriores";
            link.click();
            // For Firefox it is necessary to delay revoking the ObjectURL.
            //const fncST =setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
          }
          //        this.setState({cargando:false, });
        })
        .catch((err) => {
          //this.setState({modalShow:true, modalShowWork:false, modalTitle: "¡Lo sentimos!"});
          console.log("Err get certificate", err);

          let status = 500;
          try {
            status = err.response.status;
          } catch (err) {
            console.log(err);
          }

          //if (status === 429) { this.setState({ hasLimitError: true, modalMsg: "Tu certificado ya fue solicitado previamente. Por favor, inténtalo más tarde.", }); }
          //else { this.setState({ hasError: true, modalMsg: "Algo salió mal, por favor, inténtalo más tarde.", }); }
          //        this.setState({cargando:false, });
        });
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-warning mt-5 mb-3"
        type="button"
        onClick={handleShow}
      >
        Ver solicitudes anteriores
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Solicitudes anteriores</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <RescatesTable rescates={rescates} plan={plan} />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col className="d-flex justify-content-start">
                <button
                  className="btn btn-sm btn-warning"
                  type="button"
                  onClick={handleClose}
                >
                  <span className="ui-button-text ui-c">Aceptar</span>
                </button>
              </Col>
              <Col className="d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-danger"
                  type="button"
                  onClick={downloadPdf}
                  disabled={rescates.length === 0}
                >
                  <span>Descargar PDF</span>
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
