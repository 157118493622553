import React from "react";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
export default function RescatesTable(props) {
  
  let rescates = props.rescates;
  let plan = props.plan;
  return (
    plan.ticketNumber === null ? (<div><span style={{ color: "#D80132" }}>Esta función no está disponible porque tienes un retiro de ahorros en curso y debemos volver a calcular tu monto disponible.</span></div>) : (

    rescates.length === 0 ? (<div><span style={{ color: "#D80132" }}>No se encontraron solicitudes anteriores.</span></div>) : (
    <Table striped bordered hover size="sm" className="planes_table  mt-3">
      <thead>
        <tr>
          <th>Fecha de solicitud</th>
          <th>%</th>
          <th>Retiro en UF</th>
        </tr>
      </thead>
      <tbody>
        {React.Children.toArray(
          rescates.map((rescate, index) => (
            <tr>
              <td>{rescate.dateRequest}</td>
              <td>
                {rescate.percentRecovery}
                {" %"}
              </td>
              <td>
                <NumberFormat
                  value={rescate.amountRecoveryUF}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"UF "}
                  suffix={""}
                  decimalScale={2}
                />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
    )
  )
  );
}
